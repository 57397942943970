<template>
  <div class="container">
    <div 
    class="checkbox"
    v-bind:class="{'checked': toggled}"
    v-on:click="toggleCheckbox()"
    >
    </div>
    <div class="tape"></div>
  </div>
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    location: String
  },
  data: function(){
    return {
      toggled: false
    }
  },
  methods: {
    toggleCheckbox(){
      this.toggled = !this.toggled;
      this.$emit('toggled', this.location);
    }
  },
  mounted: function(){
  },
  watch: {
  }
}
</script>

<style scoped>
.checkbox {
  z-index: 10;
  position: absolute;
  width: 20px;
  height: 20px;
  background: #fff;
  outline: 1px solid #000;
  border: 2px solid #fff;
  transition: background 0.25s;
  cursor: pointer;
}

.checked {
  background: #666;
}

.left .checkbox {
  left: 0%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.top .checkbox {
  left: 50%;
  top: 0%;
  transform: translateX(-50%) translateY(-50%);
}

.right .checkbox {
  left: 100%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.bottom .checkbox {
  left: 50%;
  top: 100%;
  transform: translateX(-50%) translateY(-50%);
}

.tape {
  content: '';
  position: absolute;
  border: 2px solid #000;
}

.left .tape {
  top: 5px;
  bottom: 5px;
  left: -15px;
  width: 5px;
  border-right: 0px;
}

.right .tape {
  top: 5px;
  bottom: 5px;
  right: -15px;
  width: 5px;
  border-left: 0px;
}

.top .tape {
  left: 5px;
  right: 5px;
  top: -15px;
  height: 5px;
  border-bottom: 0px;
}
.top.medium .tape {
  left: 35%;
  right: 5px;
  top: -15px;
  height: 5px;
  border-bottom: 0px;
}
.top.short .tape {
  left: calc(35% - 5px);
  right: calc(35% - 5px);
  top: -15px;
  height: 5px;
  border-bottom: 0px;
}

.bottom .tape {
  left: 5px;
  right: 5px;
  bottom: -15px;
  height: 5px;
  border-top: 0px;
}

</style>
