<template>
  <div id="app">
    <div class="section">
      <div>
        <h2>1. Välj form på köket</h2>
        <ul class="button-list">
          <li><button v-bind:class="{'selected' : counter.shape === 'i'}" @click="setShape('i')">Rakt</button></li>
          <li><button v-bind:class="{'selected' : counter.shape === 'l'}" @click="setShape('l')">L-kök</button></li>
          <li><button v-bind:class="{'selected' : counter.shape === 'u'}" @click="setShape('u')">U-kök</button></li>
          <li><button v-bind:class="{'selected' : counter.extra === true}" @click="toggleExtra()">Lägg till köks-ö/extra skiva</button></li>
        </ul>
      </div>

      <div class="counter">
        <div class="shape-i active">
          <div class="inner">
            <Measurement
            class="center-bottom"
            v-if="state === 'measurements'"
            :measurement="counter.measures.i_x"
            :point="'i_x'"
            :direction="'x'"
            v-on:measure="updateMeasure"
            />
            <Measurement
            class="center-top"
            v-if="state === 'measurements' &&  counter.shape != 'i'"  
            :measurement="counter.measures.i_x_t"
            :point="'i_x_t'"
            :direction="'x'"
            v-on:measure="updateMeasure"
            />
            <Measurement
            class="left-center"
            v-if="state === 'measurements'"
            :measurement="counter.measures.i_y"
            :point="'i_y'"
            :direction="'y'"
            v-on:measure="updateMeasure"
            />
            <Checkbox 
            class="left"
            v-if="state === 'edgeSelection'"
            :location="'iLeft'"
            v-on:toggled="toggleCheckbox"
            />
            <Checkbox 
            class="top"
            :location="'iTop'"
            v-if="counter.shape == 'i' && state === 'edgeSelection'"
            v-on:toggled="toggleCheckbox"
            />
            <Checkbox 
            class="top medium"
            :location="'iTopMedium'"
            v-if="counter.shape == 'l' && state === 'edgeSelection'"
            v-on:toggled="toggleCheckbox"
            />
            <Checkbox 
            class="top short"
            :location="'iTopShort'"
            v-if="counter.shape == 'u' && state === 'edgeSelection'"
            v-on:toggled="toggleCheckbox"
            />
            <Checkbox 
            class="right"
            v-if="state === 'edgeSelection'"
            :location="'iRight'"
            v-on:toggled="toggleCheckbox"
            />
            <Checkbox 
            class="bottom"
            v-if="state === 'edgeSelection'"
            :location="'iBottom'"
            v-on:toggled="toggleCheckbox"
            />
          </div>
        </div>
        <div class="shape-l" v-bind:class="{'active' : counter.shape === 'l'||counter.shape === 'u'}">
          <div class="inner">
            <Measurement
            class="center-top"
            v-if="state === 'measurements'"
            :measurement="counter.measures.l_x"
            :point="'l_x'"
            :direction="'x'"
            v-on:measure="updateMeasure"
            />
            <Measurement
            class="right-center"
            v-if="state === 'measurements'"
            :measurement="counter.measures.l_y"
            :point="'l_y'"
            :direction="'y'"
            v-on:measure="updateMeasure"
            />
            <Checkbox 
            class="left"
            v-if="state === 'edgeSelection'"
            :location="'iLeft'"
            v-on:toggled="toggleCheckbox"
            />
            <Checkbox 
            class="top"
            v-if="state === 'edgeSelection'"
            :location="'iTop'"
            v-on:toggled="toggleCheckbox"
            />
            <Checkbox 
            class="right"
            v-if="state === 'edgeSelection'"
            :location="'iRight'"
            v-on:toggled="toggleCheckbox"
            />
          </div>
        </div>
        <div class="shape-u" v-bind:class="{'active' : counter.shape === 'u'}">
          <div class="inner">
            <Measurement
            class="center-top"
            v-if="state === 'measurements'"
            :measurement="counter.measures.u_x"
            :point="'u_x'"
            :direction="'x'"
            v-on:measure="updateMeasure"
            />
            <Measurement
            class="left-center"
            v-if="state === 'measurements'"
            :measurement="counter.measures.u_y"
            :point="'u_y'"
            :direction="'y'"
            v-on:measure="updateMeasure"
            />
            <Measurement
            class="right-center"
            v-if="state === 'measurements'"
            :measurement="counter.measures.u_y_r"
            :point="'u_y_r'"
            :direction="'y'"
            v-on:measure="updateMeasure"
            />
            <Checkbox 
            class="left"
            v-if="state === 'edgeSelection'"
            :location="'uLeft'"
            v-on:toggled="toggleCheckbox"
            />
            <Checkbox 
            class="top"
            v-if="state === 'edgeSelection'"
            :location="'uTop'"
            v-on:toggled="toggleCheckbox"
            />
            <Checkbox 
            class="right"
            v-if="state === 'edgeSelection'"
            :location="'uRight'"
            v-on:toggled="toggleCheckbox"
            />
          </div>
        </div>
      </div>
      <div class="extra" v-if="counter.extra">
        <div class="shape-e">
          <div class="inner">
            <Measurement
            class="center-bottom"
            v-if="state === 'measurements'"
            :measurement="counter.measures.e_x"
            :point="'e_x'"
            :direction="'x'"
            v-on:measure="updateMeasure"
            />
            <Measurement
            class="left-center"
            v-if="state === 'measurements'"
            :measurement="counter.measures.e_y"
            :point="'e_y'"
            :direction="'y'"
            v-on:measure="updateMeasure"
            />
            <Checkbox 
            class="left"
            v-if="state === 'edgeSelection'"
            :location="'iLeft'"
            v-on:toggled="toggleCheckbox"
            />
            <Checkbox 
            class="top"
            :location="'iTop'"
            v-if="counter.shape == 'i' && state === 'edgeSelection'"
            v-on:toggled="toggleCheckbox"
            />
            <Checkbox 
            class="right"
            v-if="state === 'edgeSelection'"
            :location="'iRight'"
            v-on:toggled="toggleCheckbox"
            />
            <Checkbox 
            class="bottom"
            v-if="state === 'edgeSelection'"
            :location="'iBottom'"
            v-on:toggled="toggleCheckbox"
            />
          </div>
        </div>
      </div>
    </div>
    
    <div class="section">
      <h2>2. Välj kantprofil och markera vilka kanter som skall fasas</h2>
      <ul class="image-list">
        <li
        v-for="(edgeShape, i) in edgeShapes"
        v-bind:key="i"
        v-bind:class="{'selected' : counter.edge.shape === edgeShape.name }"
        v-on:click="setEdgeShape(edgeShape.name)"
        >
          <img v-bind:src="edgeShape.image" />
          <p>{{ edgeShape.name }}</p>
          <div v-if="edgeShape.customRadius === true && counter.edge.shape == edgeShape.name">
            <label>Radie</label><br>
            <input class="padded-input" v-model="counter.edge.radius" />
          </div>
        </li>
      </ul>

      <div class="counter">
        <div class="shape-i active">
          <div class="inner">
            <Checkbox 
            class="left"
            :location="'iLeft'"
            v-on:toggled="toggleCheckbox"
            />
            <Checkbox 
            class="top"
            :location="'iTop'"
            v-if="counter.shape == 'i'"
            v-on:toggled="toggleCheckbox"
            />
            <Checkbox 
            class="top medium"
            :location="'iTopMedium'"
            v-if="counter.shape == 'l'"
            v-on:toggled="toggleCheckbox"
            />
            <Checkbox 
            class="top short"
            :location="'iTopShort'"
            v-if="counter.shape == 'u'"
            v-on:toggled="toggleCheckbox"
            />
            <Checkbox 
            class="right"
            :location="'iRight'"
            v-on:toggled="toggleCheckbox"
            />
            <Checkbox 
            class="bottom"
            :location="'iBottom'"
            v-on:toggled="toggleCheckbox"
            />
          </div>
        </div>
        <div class="shape-l" v-bind:class="{'active' : counter.shape === 'l'||counter.shape === 'u'}">
          <div class="inner">
            <Checkbox 
            class="left"
            :location="'iLeft'"
            v-on:toggled="toggleCheckbox"
            />
            <Checkbox 
            class="top"
            :location="'iTop'"
            v-on:toggled="toggleCheckbox"
            />
            <Checkbox 
            class="right"
            :location="'iRight'"
            v-on:toggled="toggleCheckbox"
            />
          </div>
        </div>
        <div class="shape-u" v-bind:class="{'active' : counter.shape === 'u'}">
          <div class="inner">
            <Checkbox 
            class="left"
            :location="'uLeft'"
            v-on:toggled="toggleCheckbox"
            />
            <Checkbox 
            class="top"
            :location="'uTop'"
            v-on:toggled="toggleCheckbox"
            />
            <Checkbox 
            class="right"
            :location="'uRight'"
            v-on:toggled="toggleCheckbox"
            />
          </div>
        </div>
      </div>

      <div class="extra" v-if="counter.extra">
        <div class="shape-e active">
          <div class="inner">
            <Checkbox 
            class="left"
            :location="'eLeft'"
            v-on:toggled="toggleCheckbox"
            />
            <Checkbox 
            class="top"
            :location="'eTop'"
            v-on:toggled="toggleCheckbox"
            />
            <Checkbox 
            class="right"
            :location="'eRight'"
            v-on:toggled="toggleCheckbox"
            />
            <Checkbox 
            class="bottom"
            :location="'eBottom'"
            v-on:toggled="toggleCheckbox"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <h2>3. Välj stensort</h2>
      <ul class="image-list">
        <li
        v-for="(stoneSort, i) in stoneSorts"
        v-bind:key="i"
        v-bind:class="{'selected' : counter.stoneSort === stoneSort.name }"
        v-on:click="setStoneSort(stoneSort.name)"
        >
          <img v-bind:src="stoneSort.image" />
          <p>{{ stoneSort.name }}</p>
        </li>
      </ul>
    </div>

    <div class="section">
      <h2>4. Hål för diskho</h2>
      <ul class="button-list">
        <li>
          <button
          v-bind:class="{'selected' : !counter.mainSink.selected}"
          v-on:click="counter.mainSink.selected = false"
          >
            Jag vill inte ha något hål för diskho
          </button>
        </li>
        <li>
          <button
          v-bind:class="{'selected' : counter.mainSink.selected}"
          v-on:click="counter.mainSink.selected = true"
          >
            Jag vill ha hål för diskho
          </button>
        </li>
      </ul>

      <div
      v-if="counter.mainSink.selected"
      >
        <h3>Monteringstyp</h3>
        <ul class="image-list">
          <li
          v-for="(mountingType, i) in mountingTypes"
          v-bind:key="i"
          v-bind:class="{'selected' : counter.mainSink.mounting === mountingType.name }"
          v-on:click="setMountingType(mountingType.name)"
          >
            <img v-bind:src="mountingType.image" />
            <p>{{ mountingType.name }}</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="section"
    v-if="counter.mainSink.selected"
    >
      <div>
        <h3>4.1 Modell på diskho</h3>
        <ul class="button-list">
          <li>
            <button 
            v-on:click="hasSink = true"
            v-bind:class="{'selected' : hasSink === true }"
            >Jag har redan, eller köper en ho själv</button>
          </li>

          <li>
            <button 
            v-on:click="hasSink = false"
            v-bind:class="{'selected' : hasSink === false }"
            >Jag vill även beställa en ho</button>
          </li>
        </ul>
        <div
        v-if="!hasSink"
        >
          <ul class="image-list">
            <li
            v-for="(sinkModel, i) in sinkModels"
            v-bind:key="i"
            v-bind:class="{'selected' : counter.mainSink.model === sinkModel.name }"
            v-on:click="setSinkModel(sinkModel.name)"
            >
              <img v-bind:src="sinkModel.image" />
              <p>{{ sinkModel.name }}</p>
            </li>
          </ul>
          <div v-if="counter.mainSink.customModel === true">
            <label for="">Vilken modell vill du köpa?</label><br>
            <input class="padded-input" v-model="counter.mainSink.model" />
          </div>
        </div>
        
        <h3>4.2 Bredd på diskho</h3>
        <ul class="button-list">
          <li>
            <button 
            v-on:click="counter.mainSink.width = 50"
            v-bind:class="{'selected' : counter.mainSink.width == 50}"
            >
              50cm (passar 60 cm breda skåp)
            </button>
          </li>
          <li>
            <button 
            v-on:click="counter.mainSink.width = 60"
            v-bind:class="{'selected' : counter.mainSink.width == 60}"
            >
              60cm (passar 70 cm breda skåp)
            </button>
          </li>
        </ul>

        <h3>4.3 Extra diskho</h3>
        <ul class="button-list">
          <li>
            <button 
            v-on:click="counter.secondarySink.selected = true"
            v-bind:class="{'selected' : counter.secondarySink.selected == true}"
            >
            Jag vill ha en extra diskho
            </button>
          </li>
          <li>
            <button 
            v-on:click="counter.secondarySink.selected = false"
            v-bind:class="{'selected' : counter.secondarySink.selected == false}"
            >
            Jag vill inte ha någon extra diskho
            </button>
          </li>
        </ul>

      </div>
      
    </div>

    <div class="section">
      <h2>5. Hål för häll</h2>
      <ul class="button-list">
        <li>
          <button
          v-bind:class="{'selected' : !counter.stove.selected}"
          v-on:click="counter.stove.selected = false"
          >
            Jag vill inte ha något hål för häll
          </button>
        </li>
        <li>
          <button
          v-bind:class="{'selected' : counter.stove.selected}"
          v-on:click="counter.stove.selected = true"
          >
            Jag vill ha hål för häll
          </button>
        </li>
      </ul>
    </div>

    <div class="section">
      6. Kontaktuppgifter
      <div class="form-field">
        <label for="">Ditt namn</label>
        <input type="text" class="padded-input" v-model="deliveryDetails.name">
      </div>
      <div class="form-field">
        <label for="">Ditt telefonnummer</label>
        <input type="text" class="padded-input" v-model="deliveryDetails.phone">
      </div>
      <div class="form-field">
        <label for="">Din e-postadress</label>
        <input type="text" class="padded-input" v-model="deliveryDetails.email">
      </div>
      <div class="form-field">
        <label for="">Ort för leverans</label>
        <input type="text" class="padded-input" v-model="deliveryDetails.city">
      </div>
      <div class="form-field">
        <button class="button">Skicka förfrågan</button>
      </div>
    </div>
  </div>
</template>

<script>
import Measurement from './components/Measurement.vue'
import Checkbox from './components/Checkbox.vue'

export default {
  name: 'App',
  components: {
    Measurement,
    Checkbox
  },
  data: function(){
    return {
      state: 'measurements',
      edgeShapes: [
        {
          name: 'Rak, fasad',
          image: require('@/assets/rak-fasad-kant.png'),
          customRadius: false,
          radius: 0,
        },
        {
          name: 'Rak, rundad',
          image: require('@/assets/rak-rundad-kant.png'),
          customRadius: true,
          radius: 0,
        },
        {
          name: 'Halvrundad',
          image: require('@/assets/halvrundad-kant.png'),
          customRadius: false,
          radius: 0,
        },
      ],
      mountingTypes: [
        {
          name: 'Övermonterad',
          image: require('@/assets/overmonterad.png')
        },
        {
          name: 'Undermonterad',
          image: require('@/assets/undermonterad.png')
        },
        {
          name: 'Planmonterad',
          image: require('@/assets/planmonterad.png')
        },
      ],
      stoneSorts: [
        {
          name: 'Coral Clay Color',
          image: require('@/assets/coral-clay-color.jpg')
        },
        {
          name: 'Desert Silver',
          image: require('@/assets/desert-silver.jpg')
        },
        {
          name: 'Et Serena',
          image: require('@/assets/et-serena.jpg')
        },
        {
          name: 'Et Statuario',
          image: require('@/assets/et-statuario.jpg')
        },
        {
          name: 'Lagoon',
          image: require('@/assets/lagoon.jpg')
        },
        {
          name: 'Royal Reef',
          image: require('@/assets/royal-reef.jpg')
        },
        {
          name: 'Snowy Ibiza',
          image: require('@/assets/snowy-ibiza.jpg')
        }
      ],
      sinkModels: [
        {
          name: 'Andano',
          image: require('@/assets/ho-andano.jpg')
        },
        {
          name: 'Subline',
          image: require('@/assets/ho-subline.jpg')
        },
        {
          name: 'Supra',
          image: require('@/assets/ho-supra.jpg')
        },
        {
          name: 'Claron',
          image: require('@/assets/ho-claron.jpg')
        },
        {
          name: 'Zerox',
          image: require('@/assets/ho-zerox.jpg')
        },
        {
          name: 'Annan',
          image: require('@/assets/ho-annan.jpg')
        },
      ],
      hasSink: false,
      counter: {
        extra: false,
        shape: 'i',
        mainSink: {
          selected: false,
          mounting: '',
          customModel: false,
          model: '',
          width: 0
        },
        secondarySink: {
          selected: false,
        },
        stove: {
          selected: false,
        },
        measures: {
          i_x: '',
          i_x_t: '',
          i_y: '',
          l_x: '',
          l_y: '',
          u_x: '',
          u_y: '',
          u_y_r: '',
          e_x: '',
          e_y: ''
        },
        edge: {
          shape: '',
          radius: 0,
          treatedEdges: {
            iLeft: false,
            iTop: false,
            iRight: false,
            iBottom: false,
            lLeft: false,
            lTop: false,
            lRight: false,
            lBottom: false,
            uLeft: false,
            uTop: false,
            uRight: false,
            uBottom: false,
            eLeft: false,
            eTop: false,
            eRight: false,
            eBottom: false,
          }
        },
        stoneSort: ''
      },
      deliveryDetails: {
        name: '',
        phone: '',
        email: '',
        city: ''
      }
    }
  },
  methods: {
    setShape(shape){
      this.counter.shape = shape;
    },
    setState(state){
      this.state = state;
    },
    updateMeasure(point, measurement){
      this.counter.measures[point] = measurement;
    },
    setEdgeShape(shape){
      this.counter.edge.shape = shape;
    },
    toggleCheckbox(box){
      console.log(box);
      this.counter.edge.treatedEdges[box] = !this.counter.edge.treatedEdges[box];
    },
    setStoneSort(sort){
      this.counter.stoneSort = sort;
    },
    setMountingType(type){
      this.counter.mainSink.mounting = type;
    },
    setSinkModel(model){
      if( model === 'Annan' ){
        this.counter.mainSink.customModel = true;
        this.counter.mainSink.model = '';
      } else {
        this.counter.mainSink.customModel = false;
        this.counter.mainSink.model = model;
      }
    },
    toggleExtra(){
      this.counter.extra = !this.counter.extra
    }
  },
  watch: {
    counter: {
      handler: function(){
        if( this.counter.shape === 'i' ){
          this.counter.measures.l_x = '';
          this.counter.measures.l_y = '';
          this.counter.measures.u_x = '';
          this.counter.measures.u_y = '';
        }
        if( this.counter.shape === 'l' ){
          this.counter.measures.u_x = '';
          this.counter.measures.u_y = '';
        }
      },
      deep: true
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

* {
  box-sizing: border-box;
}

li {
  cursor: pointer;
}

button:focus {
  outline: none;
}

button.button {
  background: #999999;
  border: none;
  padding: 0.5em 1em;
  color: #fff;
  font-size: 16px;
  transition: all 0.25s;
}

button {
  cursor: pointer;
}

.section {
  margin: 40px 0px;
  box-shadow: 0px 0px 32px rgba(0,0,0,0.15);
  padding: 20px;
}

input.padded-input {
  padding: 0.5em 1em;
}

.selected {
  box-shadow: 0px 0px 16px rgba(0,0,0,0.5);
}

.form-field {
  max-width: 400px;
  margin: auto;
  margin-bottom: 40px;
}

.form-field label {
  display: block;
  text-align: left;
}
.form-field input {
  width: 100%;
}

.counter {
  width: 100%;
  height: 100vw;
  max-width: 800px;
  max-height: 800px;
  margin: auto;
  position: relative;
}
.extra {
  width: 100%;
  height: 100vw;
  max-width: 800px;
  max-height: 300px;
  margin: auto;
  position: relative;
}

.counter > div, .extra > div {
  background: #ebebeb;
  border: 1px solid #3f3f3f;
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s;
}

.counter div.active, .extra div.active {
  opacity: 1;
}

.counter div .inner, .extra div .inner {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 9;
}

.counter .shape-i {
  left: 20%;
  right: 20%;
  bottom: 20%;
  height: 20%;
  width: 60%;
}

.counter .shape-l {
  left: 20%;
  bottom: 40%;
  top: 20%;
  height: 40%;
  width: 20%;
  transform: translateY(1px);
  border-bottom: none;
}

.counter .shape-u {
  right: 20%;
  bottom: 40%;
  top: 40%;
  height: 20%;
  width: 20%;
  transform: translateY(1px);
  border-bottom: none;
}

.extra .shape-e {
  top: 20%;
  left: 20%;
  right: 20%;
  bottom: 0%;
  height: 50%;
  width: 60%;
  opacity: 1;
}

ul.image-list, ul.button-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
}
.image-list li, .button-list li {
  list-style: none;
  padding: 5px;
  border: 1px solid transparent;
  transition: border 0.25s;
  border-radius: 3px;
}
.image-list li.selected {
  border: 1px solid #999999;
}
.image-list li img {
  max-width: 200px;
  height: auto;
}
.button-list li button {
  background: #999999;
  border: none;
  padding: 0.5em 1em;
  color: #fff;
  font-size: 16px;
  transition: all 0.25s;
}

.button-list li button.selected {
  background: #fefefe;
  border: 1px solid #999999;
  color: #3f3f3f;
}
</style>
