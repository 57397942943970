<template>
  <div 
  class="measurement"
  v-bind:class="'direction-' + direction"
  >
    <div class="marker"></div>
    <input type="text" v-model="localMeasurement" placeholder="Mått i mm...">
  </div>
</template>

<script>
export default {
  name: 'Measurement',
  props: {
    measurement: String,
    point: String,
    direction: String
  },
  data: function(){
    return {
      localMeasurement: ''
    }
  },
  methods: {
  },
  mounted: function(){
  },
  watch: {
    measurement: function(){
      this.localMeasurement = this.measurement;
    },
    localMeasurement: function(){
      this.$emit('measure', this.point, this.localMeasurement);
    }
  }
}
</script>

<style scoped>
.measurement {
  z-index: 10;
  position: absolute;
  width: 80px;
}
input {
  width: 100%;
  border: 1px solid #3f3f3f;
  outline: none;
  padding: 0.25em 0.5em;
  text-align: center;
}

.center-top {
  top: 0%;
  left: 50%;
}

.center-bottom {
  top: 100%;
  left: 50%;
}

.left-center {
  left: 0%;
  top: 50%;
}

.right-center {
  left: 100%;
  top: 50%;
}

.direction-y {
  transform: translateX(-50%) translateY(-30px);
}
.direction-x {
  transform: translateX(-50%) translateY(-50%);
}

</style>
